import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  images: Array<any> = [
    {
      name: 1,
      url: '../assets/pages/1.jpg',
    }, {
      name: 2,
      url: '../assets/pages/2.jpg',
    }, {
      name: 3,
      url: '../assets/pages/3.jpg',
    }, {
      name: 4,
      url: '../assets/pages/4.jpg',
    }, {
      name: 5,
      url: '../assets/pages/5.jpg',
    }, {
      name: 6,
      url: '../assets/pages/6.jpg',
    }, {
      name: 7,
      url: '../assets/pages/7.jpg',
    }, {
      name: 8,
      url: '../assets/pages/8.jpg',
    }, {
      name: 9,
      url: '../assets/pages/9.jpg',
    }, {
      name: 10,
      url: '../assets/pages/10.jpg',
    }, {
      name: 11,
      url: '../assets/pages/11.jpg',
    }, {
      name: 12,
      url: '../assets/pages/12.jpg',
    }, {
      name: 13,
      url: '../assets/pages/13.jpg',
    }, {
      name: 14,
      url: '../assets/pages/14.jpg',
    }, {
      name: 15,
      url: '../assets/pages/15.jpg',
    }, {
      name: 16,
      url: '../assets/pages/16.jpg',
    }, {
      name: 17,
      url: '../assets/pages/17.jpg',
    }, {
      name: 18,
      url: '../assets/pages/18.jpg',
    },
    {
      name: 19,
      url: '../assets/pages/19.jpg',
    },{
      name: 1,
      url: '../assets/pages/20.jpg',
    }, {
      name: 2,
      url: '../assets/pages/21.jpg',
    }, {
      name: 3,
      url: '../assets/pages/22.jpg',
    }, {
      name: 4,
      url: '../assets/pages/23.jpg',
    }, {
      name: 5,
      url: '../assets/pages/24.jpg',
    }, {
      name: 6,
      url: '../assets/pages/25.jpg',
    }, {
      name: 7,
      url: '../assets/pages/26.jpg',
    }, {
      name: 8,
      url: '../assets/pages/27.jpg',
    }, {
      name: 9,
      url: '../assets/pages/28.jpg',
    }, {
      name: 10,
      url: '../assets/pages/29.jpg',
    }, {
      name: 11,
      url: '../assets/pages/30.jpg',
    }, {
      name: 9,
      url: '../assets/pages/31.jpg',
    }, {
      name: 10,
      url: '../assets/pages/32.jpg',
    }, {
      name: 10,
      url: '../assets/pages/33.jpg',
    }, {
      name: 11,
      url: '../assets/pages/34.jpg',
    }, {
      name: 9,
      url: '../assets/pages/35.jpg',
    }, {
      name: 10,
      url: '../assets/pages/36.jpg',
    }, {
      name: 10,
      url: '../assets/pages/37.jpg',
    }, {
      name: 10,
      url: '../assets/pages/38.jpg',
    }, {
      name: 10,
      url: '../assets/pages/39.jpg',
    }, {
      name: 10,
      url: '../assets/pages/40.jpg',
    }, {
      name: 10,
      url: '../assets/pages/41.jpg',
    },{
      name: 10,
      url: '../assets/pages/42.jpg',
    }, {
      name: 10,
      url: '../assets/pages/43.jpg',
    }, {
      name: 10,
      url: '../assets/pages/44.jpg',
    }, {
      name: 10,
      url: '../assets/pages/45.jpg',
    }, {
      name: 10,
      url: '../assets/pages/46.jpg',
    }, {
      name: 10,
      url: '../assets/pages/47.jpg',
    }

  ];

  
  ngOnInit() {
  }
}

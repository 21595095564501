<nav class="navbar navbar-light bg-light" style="position: sticky; top: 0; left: 0;">
  <div class=" m-auto">
    <div class="form-group m-0">
      <input type="input" class="form-control form-control-sm" style="width: 80px; text-align: center;"
        id="index_number" name="index" placeholder="00">
    </div>
  </div>
</nav>

<div class="container-fluid custom-container">
  <div class="row row-margin">
    <div class="col-12 custom-column">
      <div id="zoom-viewport">
        <div id="flipbook" style="margin-left: auto !important; margin-right: auto !important;">
          <!-- <div class="" style="overflow: visible;">
            <img src="../assets/pages/hard-first.jpg" height="100%" width="100%" alt=""
              style="position: absolute; top: 0; left: 0;">
          </div>
          <div class="" style="overflow: visible;">
            <div class="gradient" style="z-index: 300;"></div>
            <img src="../assets/pages/hard-second.jpg" height="100%" width="100%" alt=""
              style="position: absolute; top: 0; left: 0;">
          </div> -->
          <div *ngFor="let image of images" style="overflow: visible;">
            <div class="gradient" style="z-index: 300;"></div>
            <pinch-zoom style="height: 100%">
              <img [src]="image.url" height="100%" width="100%" alt="" style="position: absolute; top: 0; left: 0;">
            </pinch-zoom>
          </div>
          <!-- <div class="">
            <div class="gradient" style="z-index: 300;"></div>
            <img src="../assets/pages/after-sales.jpg" height="100%" width="100%" alt=""
              style="position: absolute; top: 0; left: 0;">
          </div>
          <div class="">
            <img src="../assets/pages/hard-last.jpg" height="100%" width="100%" alt=""
              style="position: absolute; top: 0; left: 0;">
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>

<div class="flip_button_left hide-small">
  <i class="fas fa-chevron-left"></i>
</div>
<div class="flip_button_right hide-small">
  <i class="fas fa-chevron-right"></i>
</div>
<div class="text-center m-auto">
  <div class="footer py-2">
    <button class="btn" type="button" name="fast-backward" id="fastbackward">
      <i class="fas fa-angle-double-left"></i>
    </button>
    <button class="btn" type="button" name="backward" id="backward">
      <i class="fas fa-angle-left"></i>
    </button>
    <!-- <button class="btn" type="button" name="backward" id="zoom">
      <i class="fas fa-search"></i>
    </button> -->
    <!-- <button class="btn" type="button" id="playButton"><i
        class="fas fa-play"></i></button>
    <button class="btn" type="button" id="pauseButton"><i
        class="fas fa-pause"></i></button>
    <button class="btn" type="button" onclick="document.getElementById('player').volume-=0.1"><i
        class="fas fa-volume-down"></i></button>
    <button class="btn" type="button" onclick="document.getElementById('player').volume+=0.1"><i
        class="fas fa-volume-up"></i></button> -->
    <button class="btn" type="button" name="forward" id='forward'>
      <i class="fas fa-angle-right"></i>
    </button>
    <button class="btn" type="button" name="fast-forward" id='fastforward'>
      <i class="fas fa-angle-double-right"></i>
    </button>
  </div>
</div>